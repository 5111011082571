import {
  AppletTemplateFrame,
  WidgetBar,
  WidgetBarButton,
} from '@tapestry/shared/components';
import { FC } from 'react';
import '../styles/react-grid-layout.css';
import '../styles/react-resizable.css';
import { METRICS, ROUTE_PATHS } from '@tapestry/shared/constants';
import MeasureVisualisation from './components/MeasureVisualisation';
import {
  IMetric,
  MeasureSlug,
  THREAD_TYPE,
  Visualization,
} from '@tapestry/types';
import { dateTime } from '@tapestry/shared/utils';
import { useActiveShopScopeParams, useProfile } from '@tapestry/shared/hooks';
import HorizontalBarChartVisualisation from './components/HorizontalBarChartVisualisation/HorizontalBarChartVisualisation';
import { TasksListVisualisation } from './components/TasksListVisualisation';
import { useRouter } from 'next/router';
import { BarcodeScannerIcon, PlusIcon } from '@tapestry/shared/icons';
import { twMerge } from 'tailwind-merge';
import { useFlags } from 'launchdarkly-react-client-sdk';

const totalSales = METRICS.find(
  (metric) => metric.key === MeasureSlug.TotalSales
) as IMetric;

/**
 * @see https://github.com/react-grid-layout/react-grid-layout
 * ! 23 July 2024 - Temporarily commented out due to a unpleasant calculation resize in react-grid-layout and our widget not being designed for a fixed row size
 * config in './constants/grid-config'
 */
// const ResponsiveGridLayout = WidthProvider(Responsive);

export const RetailDashboard: FC = () => {
  const [profile] = useProfile();
  const currentWeekDates = dateTime.getCurrentIsoWeek(
    profile?.active_shop_scope?.timezone
  );
  const scope = useActiveShopScopeParams();
  const router = useRouter();
  const { scanner: hasScannerPermission } = useFlags();

  const productChartVars = {
    startDate: currentWeekDates.startDate,
    endDate: currentWeekDates.endDate,
    scopeType: '',
    threadType: THREAD_TYPE.PRODUCT,
    measure: totalSales.key,
    scopeId: null,
    filters: JSON.stringify(scope),
  };

  /**
   * TODO[low]:
   * * react-grid-layout - memoise grid children for faster rendering
   */
  return (
    <main>
      <AppletTemplateFrame title="Home">
        {/* ! 23 July 2024 - Temporarily commented out due to a unpleasant calculation resize in react-grid-layout and our widget not being designed for a fixed row size */}
        {/* <ResponsiveGridLayout
          className="layout mt-4"
          // * these breakpoint mirror the tailwind breakpoints
          breakpoints={{ md: 769, xs: 481, xxs: 0 }}
          cols={{ md: 3, xs: 2, xxs: 1 }}
          layouts={{
            md: LAPTOP_LAYOUT,
            xs: TABLET_LAYOUT,
            xxs: PHONE_LAYOUT,
          }}
          rowHeight={ROW_HEIGHT}
          margin={[16, 16]}
          containerPadding={[0, 0]}
          isBounded={true}
          isDraggable={dynamicRetailDashboardFlag ?? false}
          isResizable={dynamicRetailDashboardFlag ?? false}
        > */}
        <div
          className={twMerge(
            'xs:space-y-0 mt-4 space-y-4',
            'xs:grid xs:grid-cols-2 xs:gap-4 xs:items-start md:grid-cols-3'
          )}
        >
          <div key={MeasureSlug.TotalSales} className="md:space-y-4">
            <MeasureVisualisation
              measure={totalSales}
              dateRange={currentWeekDates}
              scope={scope}
            />

            <div className="hidden md:block">
              <HorizontalBarChartVisualisation
                // * In Heartbeat, some horizontal chart use `dimensionsLabels` present on the measure to display the title. no `dimensionsLabels` exist for topPorduct hence not currently using it here.
                title="Top Products"
                queryVariables={productChartVars}
                unit={totalSales?.unit}
                visualisation={Visualization.TopProducts}
              />
            </div>
          </div>

          <div
            key={Visualization.TasksList}
            className="xs:col-span-2 xs:col-start-1 xs:row-start-1 h-full md:col-start-2"
          >
            <TasksListVisualisation />
          </div>

          <div key={Visualization.TopProducts} className="md:hidden">
            <HorizontalBarChartVisualisation
              // * In Heartbeat, some horizontal chart use `dimensionsLabels` present on the measure to display the title. no `dimensionsLabels` exist for topPorduct hence not currently using it here.
              title="Top Products"
              queryVariables={productChartVars}
              unit={totalSales?.unit}
              visualisation={Visualization.TopProducts}
            />
          </div>
        </div>
        {/* ! 23 July 2024 - Temporarily commented out due to a unpleasant calculation resize in react-grid-layout and our widget not being designed for a fixed row size */}
        {/* </ResponsiveGridLayout> */}
      </AppletTemplateFrame>

      <WidgetBar>
        {hasScannerPermission && (
          <div className="block">
            <WidgetBarButton
              title="Scan a code"
              action="scan-code"
              onPress={() => router.push(ROUTE_PATHS.scanner)}
              variant="secondary"
              icon={<BarcodeScannerIcon fillColor="#fff" />}
            />
          </div>
        )}

        <WidgetBarButton
          title="Create new task"
          action="create-new-task"
          onPress={() => router.push(ROUTE_PATHS.createNewTask)}
          icon={<PlusIcon light fillColor="currentColor" />}
          variant="primary"
        />
      </WidgetBar>
    </main>
  );
};

export default RetailDashboard;
